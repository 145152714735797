@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

@layer base {
    .navbar ul {
        @apply py-small transition-all duration-300 ease-in-out
    }
    .navbar.scrolled ul{
        @apply py-extra-extra-small
    }

    .blend-overlay {
        mix-blend-mode: overlay;
    }

    body {
        @apply bg-merino;
    }

    h1, .h1 {
        @apply text-aztec text-4xl md:text-5xl lg:text-6xl font-bold max-w-4xl mb-small;
    }
    p, .p {
        @apply text-aztec text-lg mb-extra-extra-small;
    }
    h2, h3, h4, h5, h6 {
        @apply text-eden;
    }

    h2, .h2 {
        @apply text-3xl md:text-4xl lg:text-5xl font-bold max-w-4xl mb-extra-small;
    }
    h3, .h3 {
        @apply text-2xl md:text-3xl lg:text-4xl font-bold max-w-5xl mb-extra-small;
    }
    h4, .h4 {
        @apply text-xl md:text-2xl lg:text-3xl font-bold max-w-4xl mb-extra-extra-small;
    }
    h5, .h5 {
        @apply text-lg md:text-xl lg:text-2xl font-bold max-w-3xl mb-extra-extra-small;
    }
    h6, .h6 {
        @apply text-base md:text-lg lg:text-xl font-bold max-w-2xl mb-extra-extra-small;
    }

    .container {
        @apply px-10;
    }
    section {
        @apply py-section-mobile md:py-section;
    }

    .featured-heading {
        @apply text-putty;
    }
    .featured-subheading {
        @apply text-merino;
    }
    .featured-paragraph {
        @apply text-merino;
    }

    .button {
        @apply px-8 py-2 rounded-full;
    }

    .button-eden {
        @apply button bg-eden text-merino;
    }
    .button-putty {
        @apply button bg-putty text-aztec;
    }

    .label {
        @apply text-merino flex flex-col gap-y-1;
    }
    .input {
        @apply text-aztec px-4 py-2 rounded-xl outline-none ring-eden;
    }
}